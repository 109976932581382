import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "using-transitions-for-simple-animation"
    }}>{`Using transitions for simple animation`}</h1>
    <p>{`Game loops are great when you need fine-grained control. But what, if you just want an element to animate a little bit when a user does something? You don't care about the details, you just want a little flourish.`}</p>
    <p>{`That's where transitions come in.`}</p>
    <p>{`Transitions are a way to animate SVG elements by saying "I want this property to change to this new value and take this long to do it". And you can use easing functions to make it look better.`}</p>
    <p>{`I won't go into details about why easing functions are important, but they make movement look more natural. You can read more about it in Disney's 12 Basic Principles of Animation.`}</p>
    <p>{`The two we can achieve with easing functions are:`}</p>
    <ul>
      <li parentName="ul">{`Squash and Stretch`}</li>
      <li parentName="ul">{`Slow In Slow Out`}</li>
    </ul>
    <iframe src="http://easings.net/" width="110%" height="400" style={{
      "border": "0px"
    }}></iframe>
    <p><a parentName="p" {...{
        "href": "http://easings.net"
      }}>{`From easings.net`}</a></p>
    <p>{`Let me show you how it works on a small example. We're creating a component that swipes left or right when you click. You can think of it as a toggle button.`}</p>
    <h2 {...{
      "id": "a-ball-swipe-transition"
    }}>{`A Ball swipe transition`}</h2>
    <iframe {...{
      "src": "https://codesandbox.io/embed/d3-transition-ball-swipe-hooks-n60lb",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`Just like with earlier examples, our goal is to build a component that's fully controlled by its props. We're still using React for rendering and D3 for calculating things, but we have to mix approaches.`}</p>
    <ul>
      <li parentName="ul">{`React controls the DOM`}</li>
      <li parentName="ul">{`D3 takes over during transition`}</li>
      <li parentName="ul">{`React regains control`}</li>
    </ul>
    <p>{`We're using `}<inlineCode parentName="p">{`state`}</inlineCode>{` as a staging area for our prop changes. That allows us to apply changes over time.`}</p>
    <p>{`A 4-step approach develops:`}</p>
    <ol>
      <li parentName="ol">{`Copy relevant props into state`}</li>
      <li parentName="ol">{`Render from state`}</li>
      <li parentName="ol">{`Use D3 transitions in `}<inlineCode parentName="li">{`componentDidUpdate`}</inlineCode></li>
      <li parentName="ol">{`Update state when transition ends`}</li>
    </ol>
    <p>{`It's important to tell React what's going on after we're done updating the DOM. Otherwise it gets confused and might start throwing errors about DOM nodes not matching their React state.`}</p>
    <h3 {...{
      "id": "practical-exercise"
    }}>{`Practical exercise`}</h3>
    <p>{`Fork the sandbox above and add a similar transition on the vertical axis. Or maybe a circle size transition.`}</p>
    <p>{`Try different easing functions as well. `}<a parentName="p" {...{
        "href": "https://github.com/d3/d3/blob/master/API.md#easings-d3-ease"
      }}>{`Here`}</a>{`'s a list of all that exist.`}</p>
    <p>{`Here's my solution:`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/d3-transition-ball-swipe-hooks-hbj3x",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`Try what happens when you chain the transitions.`}</p>
    <h2 {...{
      "id": "a-note-on-transition-and-animation-performance"
    }}>{`A note on transition and animation performance`}</h2>
    <p>{`JavaScript transitions and animations are great when you need a lot of control over your transitions. Or when you need to stack and coordinate different animations.`}</p>
    <p>{`But they have a big flaw: No GPU optimization.`}</p>
    <p>{`When you run into trouble depends on what exactly your'e doing, but eventually you're going to struggle with UI lag and jitteriness. You can solve this by using CSS transitions instead.`}</p>
    <p>{`The approach is similar, even easier in some cases. You don't have to worry about props and state at all, just set up the transition in your CSS and the browser will handle everything. You can render the same old way.`}</p>
    <h3 {...{
      "id": "why-even-use-d3-transitions-then"
    }}>{`Why even use D3 transitions then?`}</h3>
    <p>{`Good question!`}</p>
    <p>{`Think of it as a spectrum of control and performance.`}</p>
    <p>{`Game loop gives you most control and least performance. You're running React's full rendering engine on every update.`}</p>
    <p>{`D3 transition gives you a lot of control and decent performance. You're running javascript for transitions, but updating the DOM directly.`}</p>
    <p>{`CSS transition gives you least control and best performance. You change props and the GPU optimized CSS engine handles the rest.`}</p>
    <p>{`There are ways to make both game loops and D3 transitions more or less performant, but consider whether it's worth it to go through all that trouble.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      